$light-gray: #f5f5f5;
$light-gray-2: #d8e0f3;
$light-gray-3: #fcfcfc;
$light-gray-4: #f5f6fa;
$light-gray-5: #ebecf2;
$light-gray-6: #d7d7d7;
$light-gray-7: #e9e9e9;
$light-gray-8: #b8b8b8;
$light-gray-9: #eeeeee;
$light-gray-10: #e5e5e5;
$light-gray-11: #f8f8f8;
$light-gray-12: #dddddd;
$light-gray-13: #e8e8ea;
$light-gray-14: #cccccc;
$light-gray-15: #fafafa;
$light-gray-16: #d1d1d1;
$light-gray-17: #bbbbbb;

$gray: #677791;
$gray-2: #646464;
$gray-3: #999999;
$gray-4: #333333;
$gray-3: #999;
$gray-4: #333;
$gray-5: #d8e0f3;
$gray-6: #fff;
$gray-7: #b8b8b8;
$gray-8: #707070;

$blue: #0b8dae;
$blue-2: #0b8dae;
$blue-3: #4aa4dc;
$blue-4: #3fa9f5;
$blue-5: #45cbed;
$blue-6: #42a5f5;
$blue-7: #1e88e5;
$blue-8: #166dba;
$blue-9: #00bcd4;

$green: var(--affirmative);
$green-1: #4caf50;
$green-2: #46da60;
$dark-blue: #3fa9f5;
$dark-blue-2: #005aa4;

$dark-green: #0d8923;
$red: #f44336;
$red-1: #ff5722;
$blue-6: #0369b1;
$blue-7: #46b0fc;
$dark-blue: #3fa9f5;
$dark-blue-2: #005aa4;
$blue-6: #0369b1;
$blue-7: #46b0fc;
$blue-8: #46b0fc;
$blue-9: #46b0fc;
$dark-blue: #3fa9f5;
$dark-blue-2: #005aa4;
$dark-blue-3: #021e29;
$light-blue: #f3f7fa;

$green: #46da60;
$green-1: #46da60;
$green-2: #21605f;
$dark-green: #0d8923;
$red: #f44336;
$red-1: #f44336;
$red-3: #af4e0e;
$orange: #fe6f21;
$orange-2: #fc6e62;

$white: var(--white);
$light-white-2: #f8fbf8;
$light-green: #b6f3d4;
$light-green-2: #abe9b6;
$light-blue: #ade9f9;

$default-heading: var(--action);

:root {
	--text: #222222;
	--action: #677791;
	--blue: #45cbed;
	--action-hover: #02548e;
	--secondary-text: #707070;
	--affirmative: #89bd30;
	--warning: #af4e0e;
	--danger: #c12c20;
	--informative: #1a72cb;
	--elements-background: #ffffff;
	--background-blue: #f3f7fa;
	--vendor-font: Helvetica;
	--header: #677791;
	--white: #ffffff;
	--suggestionBox: #89bd30;
}

$text: var(--text);

$action: var(--action);

$action-hover: var(--action-hover);

$secondary-text: var(--secondary-text);

$affirmative: var(--affirmative);

$warning: var(--warning);

$danger: var(--danger);

$informative: var(--informative);

$elements-background: var(--elements-background);

$background-blue: var(--background-blue);

$vendor-font: var(--vendor-font);

$linkedin: #0077b5;
$twitter: #1da1f2;
$skype: #01aff0;
$facebook: #4767aa;
$pinterest: #bd081c;
$instagram: #d2318f;
$whatsapp: #25d366;
