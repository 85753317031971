@import "~bootstrap/dist/css/bootstrap.css";
@import "~@swimlane/ngx-datatable/index.css";
@import "~@swimlane/ngx-datatable/themes/material.css";
@import "~@swimlane/ngx-datatable/assets/icons.css";
@import "~ngx-bootstrap/datepicker/bs-datepicker.css";
@import "~rangeslider.js/dist/rangeslider.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~pretty-checkbox/src/pretty-checkbox.scss";
@import "assets/css/icons/fontawesome/styles.min.css";
@import "assets/css/icons/icomoon/styles.css";
@import "assets/css/icons/font-ht/styles.css";
@import "assets/css/buttons.css";
@import "assets/css/loader-animation.css";
@import "assets/css/core.css";
@import "assets/css/colors.css";
@import "assets/css/cropper.css";
@import "assets/css/cropper-layout.css";
@import "assets/css/bootstrap-datetimepicker.css";
@import "assets/sass/components.scss";
@import "assets/sass/jquery.orgchart.scss";
@import "assets/sass/variables.scss";
@import "assets/sass/color-scheme.scss";
@import "assets/sass/buttons.scss";
@import "assets/sass/custom.scss";
@import "assets/sass/auth.scss";
@import "assets/sass/validations.scss";
@import "assets/sass/media-queries.scss";
@import "assets/sass/plugins-overrides.scss";
@import "assets/sass/news-events.scss";
@import "assets/sass/users-anniversaries.scss";
@import "assets/sass/limitless-overrides.scss";
@import "assets/sass/bootstrap-overrides.scss";
@import "assets/sass/animations.scss";
@import "assets/sass/ngx-charts-overrides.scss";
@import "assets/sass/summernote-overrides.scss";
@import "@helloteaminc/front-common/styles/pnotify";
@import "@helloteaminc/front-common/styles/tables";
@import "~@angular/cdk/overlay-prebuilt.css";

@import 'animate.css/animate.min.css';


@tailwind base;
@tailwind components;
@tailwind utilities;

@import './syncfusion/tailwind.scss'; // customized css from https://ej2.syncfusion.com/themestudio/?theme=tailwind

// @import '@syncfusion/ej2-base/styles/tailwind.css';
// @import '@syncfusion/ej2-buttons/styles/tailwind.css';
// @import '@syncfusion/ej2-calendars/styles/tailwind.css';
// @import '@syncfusion/ej2-dropdowns/styles/tailwind.css';
// @import '@syncfusion/ej2-grids/styles/tailwind.css';
// @import '@syncfusion/ej2-inputs/styles/tailwind.css';
// @import '@syncfusion/ej2-lists/styles/tailwind.css';
// @import '@syncfusion/ej2-navigations/styles/tailwind.css';
// @import '@syncfusion/ej2-popups/styles/tailwind.css';
// @import '@syncfusion/ej2-splitbuttons/styles/tailwind.css';
// @import '@syncfusion/ej2-angular-base/styles/tailwind.css';
// @import '@syncfusion/ej2-angular-buttons/styles/tailwind.css';
// @import '@syncfusion/ej2-angular-calendars/styles/tailwind.css';
// @import '@syncfusion/ej2-angular-dropdowns/styles/tailwind.css';
// @import '@syncfusion/ej2-angular-grids/styles/tailwind.css';
// @import '@syncfusion/ej2-angular-inputs/styles/tailwind.css';
// @import '@syncfusion/ej2-angular-lists/styles/tailwind.css';
// @import '@syncfusion/ej2-angular-navigations/styles/tailwind.css';
// @import '@syncfusion/ej2-angular-popups/styles/tailwind.css';
// @import '@syncfusion/ej2-angular-splitbuttons/styles/tailwind.css';

// @import '@syncfusion/ej2-angular-layouts/styles/tailwind.css';
// @import '@syncfusion/ej2-angular-schedule/styles/tailwind.css';

.animate__animated {
	animation-duration : 400ms !important;
}